<template>
    <div class="page">
        <div class="w1000">
            <topHeader :isShowBack="false"></topHeader>
            <div class="btn-group">
               <div class="btntype flexrow">
               	<div style="margin-right: 16px;" @click="handleordertype(0)">
               		<el-button v-if="orderindex!=0">全部</el-button>
               		<el-button v-if="orderindex==0" type="primary" plain>全部{{orderList.length}}</el-button>
               	</div>
               	<div style="margin-right: 16px;" @click="handleordertype(1)">
               		<el-button v-if="orderindex!=1">已支付</el-button>
               		<el-button v-if="orderindex==1" type="primary" plain>已支付{{orderList.length}}</el-button>
               	</div>
               	<div style="margin-right: 16px;" @click="handleordertype(2)">
               		<el-button v-if="orderindex!=2">已兑换</el-button>
               		<el-button v-if="orderindex==2" type="primary" plain>已兑换{{orderList.length}}</el-button>
               	</div>
               	<!-- <div @click="handleordertype(3)">
               		<el-button v-if="orderindex!=3">已失效</el-button>
               		<el-button v-if="orderindex==3" type="primary" plain>已失效{{orderList.length}}</el-button>
               	</div> -->
               </div>
                <div class="dh_btn" @click="handleduihuan">
                    <img src="@/assets/static/assets_vip.png" alt="" srcset="">
                </div>
            </div>

            <div class="order-list">
                <div class="order-item" v-for="(item, index) in orderList" :key="index">
                    <div class="top-col">
                        <div class="order-num">
                            <img src="@/assets/static/order_icon.png" alt="" srcset="">
                            <span>订单编号：{{item.orderNum}}</span>
                        </div>

                        <div class="date">{{item.createDate}}</div>
                    </div>
                    <div class="bottom-col">
                      <!--  <img src="@/assets/static/example.png" alt="" srcset=""> -->
                        <div class="layout1">
                            <div class="book-title">{{item.booksGoods.goodsName}}</div>
                            <div class="book-desc"></div>
                            <div class="book-origin-price">￥{{item.booksGoods.goodsOriPrice}}</div>
                        </div>
                        <div class="layout2">￥{{item.booksGoods.goodsPrice}}</div>
                        <div class="layout3 mauto">
                            <div class="sub-btn" v-if="item.orderState==2">支付成功</div>
							<div class="sub-btn" v-if="item.orderState==3">兑换成功</div>
                           <!-- <span v-if="index!==1">申请退款</span> -->
                        </div>
                    </div>
                </div>
            </div>
			<!-- 空 -->
			<div class="data_empty_box" style="padding-top: 68px;" v-if="orderList.length<=0">
				<div class="icon"><img class="img100" :src="require('@/assets/static/kong.png')"></img></div>
				<div class="text">暂无数据</div>
			</div>
			<!-- 空 -->
        </div>
    </div>
</template>

<script>
import topHeader from '@/components/topHeader/topHeader.vue';
import { mapState,mapMutations } from 'vuex'
import {
    showLoading,
    hideLoading
} from '@/common/loading.js';
export default {
    components: {
        topHeader
    },
    data() {
        return {
			orderindex:0,//下标
			orderList:[],//订单
        };
    },
    mounted: function () {
        // console.log(this.userInfo)
		//获取订单
		this.getallorder()
    },
    computed: {
        ...mapState(['userInfo', 'isLogin']),
    },
    methods: {
		...mapMutations(["SET_Data"]),
		//订单下标
		handleordertype(type){
			this.orderindex = type
			this.orderList = []
			this.getallorder()
		},
		//退款
		handletuikuan(index){
			this.$alert('您好，请在首页添加客服进行退款！', '提示', {
			    confirmButtonText: '确定',
			    callback: action => {}
			});
		},
		//兑换会员
		handleduihuan(){
			var _this = this
			this.$prompt('请输入兑换码', '兑换会员', {
			        confirmButtonText: '确定兑换',
			        cancelButtonText: '取消',
			        inputPlaceholder:"请输入"
			    }).then(({ value }) => {
					if(value){
						var params = {
							code: value,
							userUuid: _this.userInfo.uuid
						}
						_this.$http.post('app/word/booksGoods/code/geUserVipByCode', params).then(function(res) {
							//数据处理
							if (res.code == 200) {
								//刷新用户信息
								_this.refreshUser()
								_this.$alert('兑换成功！', '提示', {
								    confirmButtonText: '确定',
								    callback: action => {}
								});
							} else {
								_this.$confirm(res.message, '提示', {
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning'
								}).then(() => {}).catch(() => {});
							}
						})
					}else{
						_this.$message({
							type: 'error',
							message: '请输入兑换码 '
						});
					}
					
			    }).catch(() => {
			        this.$message({
			            type: 'info',
			            message: '取消输入'
			        });       
			});
		},
		//刷新用户信息
		refreshUser(){
			var _this = this
			var params = {
				id: this.userInfo.id
			}
			this.$http.post('app/hlexam/hlUser/detail', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					_this.SET_Data({ key: 'userInfo', value:res.data})
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//请求数据
		getallorder(){
			var _this = this
			var params = {
				currentPage: 1,
				pageSize: 888
			}
			params["userUuid"] = this.userInfo.uuid
			if(this.orderindex==0){
				params["orderState"] = [2,3]
			}
			if(this.orderindex==1){
				params["orderState"] = [2]
			}
			if(this.orderindex==2){
				params["orderState"] = [3]
			}
			if(this.orderindex==3){
				params["orderState"] = [0,1]
			}
			this.$http.post('app/word/orders/books-orders/PageBooksOrders', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records
					// records.forEach((item, index) => {
						
					// });
					_this.orderList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},

    }
};
</script>
<style lang="scss" scoped>
@import '@/css/main.scss';
.btn-group {
    margin-top: 40px;
    display: flex;

    .btn-item {
        width: 88px;
        height: 41px;
        margin-right: 16px;
        margin-left: 0;

        span:last-child {
            margin-left: 2px;
        }
    }

    .checked-item {
        background: #E5EAFA;
        color: #1682FD;
    }

    .dh_btn {
        margin-left: auto;
        width: 120px;
        height: 42px;
        cursor: pointer;

        &:hover {
            transform: scale(1.01);
        }
    }
}

.order-list {
    margin-top: 20px;

    .order-item {
        width: 1000px;
        height: 214px;
        border-radius: 16px;
        box-shadow: 0px 3px 15.5px 0px #0000001A;
        background: #fff;
        margin-bottom: 20px;
    }

    .top-col {
        height: 60px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #F0F0F0;
        padding: 0 24px;
        color: #666;

        img {
            width: 20px;
            height: 20px;
            margin-right: 8px;
        }

        .date {
            margin-left: auto;
            color: #666;
            font-size: 14px;
        }
    }

    .bottom-col {
        height: 154px;
        align-items: center;
        display: flex;
        padding: 0 24px;


        img {
            width: 185px;
            height: 100px;
            flex-shrink: 0;
            border-radius: 6px;
        }

        .layout1 {
            margin-left: 18px;
            height: 100px;
            width: 320px;

            .book-title {
                font-size: 20px;
                font-weight: bold;
                color: #5C3704;
            }

            .book-desc {
                color: #666666;
                margin-top: 10px;
                height: 42px;
                font-size: 14px;
                line-height: 18px;
                @include text-line(2);
            }

            .book-origin-price {
                font-size: 16px;
                color: #FF0000;
                text-decoration: line-through;
                opacity: 0.6;
            }
        }

        .layout2 {
            color: #F00;
            font-size: 16px;
            font-weight: bold;
            height: 100px;
            width: 165px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 50px;
            border-left: 1px solid #F0F0F0;
            border-right: 1px solid #F0F0F0;
        }


        .layout3 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-left: 89px;
            height: 100px;
            flex-shrink: 0;

            .sub-btn {
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                flex-shrink: 0;
                border-radius: 20px;
                background: #FE8C22;
                box-shadow: 0px -2px 0px 0px #F07400 inset;
                color: #fff;
                line-height: 40px;
                width: 118px;
                cursor: default;
            }

            span {
                margin-top: 12px;
                font-size: 16px;
                color: #666;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

}
</style>
